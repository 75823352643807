/*! morpher site */
$( "#menu-overlay-button" ).click(function() {
$( this ).toggleClass( "active" );
$( "#menu-overlay" ).toggleClass( "active" );
$( "body" ).toggleClass( "menu-active" );  
});

$( ".navitem_mobile" ).click(function() {
$( "#menu-overlay-button" ).toggleClass( "active" );
$( "#menu-overlay" ).toggleClass( "active" );
$( "body" ).toggleClass( "menu-active" );   
});

$(window).scroll(function(){
if ($(window).scrollTop() >= 300) {
$( ".menu_logo" ).addClass( "showlogo" ); 
}
else {
$( ".menu_logo" ).removeClass( "showlogo" );
}
}); 